import cleanDeep from 'clean-deep';
import _ from 'lodash';
import moment from 'moment-timezone';

const getArrayOutOfServerObject = (obj) => {
    let arr = []
    Object.keys(obj).forEach(x => {
        if (x == '0' || Number(x)) {
            arr.push(obj[x]);
        }
    });
    return arr
}

const cleanEntityData = (data) => {
    let cleanData = {};
    try{
        cleanData = cleanDeep(data);
    } catch (e) {
        console.log(e);
    }
    return cleanData;
}

const createReqObjForCart = ()=>{
    let reqObj = {}
    if(localStorage.getItem("Token"))
    reqObj = {
        "api_token": localStorage.getItem("Token"),
        "cart_id":localStorage.getItem("cart_id")
    };
    else{
        reqObj = {
            "cart_id":localStorage.getItem("cart_id")
        };
    }
    return reqObj;
};

const formatPrice = (price) => {
    const newPrice = price.replace(/,/g, '');
    return Number(newPrice);
};

const enrichArrDataToObj = ({ data, field = ''}) => _.reduce(data, (acc, val) => ({ ...acc, [_.get(val, field)]: val }), {});


const deliveryMethods = Object.freeze({
    'Same Day': 'Local Delivery',
    'Next Day': 'Courier Delivery',
    'Farm Direct': 'Farm Direct'
});

const productListingDeliveryMethods = Object.freeze({
    'Same Day': 'Local',
    'Next Day': 'Courier',
    'Farm Direct': 'Farm Direct'
});

const deliveryMethodsAbb = Object.freeze({
    'Same Day': 'local',
    'Next Day': 'courier',
    'Farm Direct': 'farm-direct'
});


const deliveryMethodsAbbReverse = Object.freeze({
    'local': 'Same Day',
    'courier': 'Next Day',
    'farm-direct': 'Farm Direct'
});

const presentDateInEST = () => {
    const normalizedLocalTimeInEST = moment.tz("America/New_York").set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
    return normalizedLocalTimeInEST;
}

const oneDateLaterInEST = () => {
    const normalizedLocalTimeInEST = moment.tz("America/New_York").set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });

    const oneDayLater = normalizedLocalTimeInEST.clone().add(1, 'days');
    return oneDayLater;
}






export {
    cleanEntityData,
    createReqObjForCart,
    formatPrice,
    enrichArrDataToObj,
    getArrayOutOfServerObject,
    deliveryMethods,
    deliveryMethodsAbb,
    deliveryMethodsAbbReverse,
    presentDateInEST,
    oneDateLaterInEST,
    productListingDeliveryMethods
}