const data = [
    {
        "id": "32253",
        "sku": "KAFL0018",
        "name": "PREMIUM 6 Orange Roses Bouquet ( 6 Fresh Cut Orange Roses )",
        "type": "config",
        "short_description": "This is a short description",
        "description": "Introducing the Kabloom 10 Mixed Purple and White Orchids Bouquet a perfect blend of elegance and sophistication. These stunning orchids, known for their exotic beauty, feature a delightful mix of deep purple and pure white petals, creating a striking contrast that instantly captivates. This bouquet is ideal for a variety of occasions, from celebrating anniversaries and birthdays to expressing heartfelt gratitude or sending best wishes. Whether youre surprising a loved one or brightening up a special event, the Kabloom orchid arrangement is designed to leave a lasting impression, bringing a touch of natures grace into any setting.",
        "meta_title": "test meta title 2",
        "meta_description": "test meta description 2",
        "meta_keywords": null,
        "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_4.jpg",
        "stock_status": "in stock",
        "child": [
          {
            "id": "32251",
            "sku": "KAFL0018-A999-V118",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "38.13",
            "price": "38.13",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose.jpg",
            "bottle_size": "With Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "38.13"
              }
            ],
            "stock_availability": "In Stock"
          },
          {
            "id": "32252",
            "sku": "KAFL0018-A999-V999",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "34.83",
            "price": "34.83",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_3.jpg",
            "bottle_size": "No Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "34.83"
              }
            ],
            "stock_availability": "In Stock"
          }
        ]
      },
      {
        "id": "32253",
        "sku": "KAFL0018",
        "name": "PREMIUM 6 Orange Roses Bouquet ( 6 Fresh Cut Orange Roses )",
        "type": "config",
        "short_description": "This is a short description",
        "description": "Introducing the Kabloom 10 Mixed Purple and White Orchids Bouquet a perfect blend of elegance and sophistication. These stunning orchids, known for their exotic beauty, feature a delightful mix of deep purple and pure white petals, creating a striking contrast that instantly captivates. This bouquet is ideal for a variety of occasions, from celebrating anniversaries and birthdays to expressing heartfelt gratitude or sending best wishes. Whether youre surprising a loved one or brightening up a special event, the Kabloom orchid arrangement is designed to leave a lasting impression, bringing a touch of natures grace into any setting.",
        "meta_title": "test meta title 2",
        "meta_description": "test meta description 2",
        "meta_keywords": null,
        "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_4.jpg",
        "stock_status": "in stock",
        "child": [
          {
            "id": "32251",
            "sku": "KAFL0018-A999-V118",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "38.13",
            "price": "38.13",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose.jpg",
            "bottle_size": "With Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "38.13"
              }
            ],
            "stock_availability": "In Stock"
          },
          {
            "id": "32252",
            "sku": "KAFL0018-A999-V999",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "34.83",
            "price": "34.83",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_3.jpg",
            "bottle_size": "No Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "34.83"
              }
            ],
            "stock_availability": "In Stock"
          }
        ]
      },
      {
        "id": "32253",
        "sku": "KAFL0018",
        "name": "PREMIUM 6 Orange Roses Bouquet ( 6 Fresh Cut Orange Roses )",
        "type": "config",
        "short_description": "This is a short description",
        "description": "Introducing the Kabloom 10 Mixed Purple and White Orchids Bouquet a perfect blend of elegance and sophistication. These stunning orchids, known for their exotic beauty, feature a delightful mix of deep purple and pure white petals, creating a striking contrast that instantly captivates. This bouquet is ideal for a variety of occasions, from celebrating anniversaries and birthdays to expressing heartfelt gratitude or sending best wishes. Whether youre surprising a loved one or brightening up a special event, the Kabloom orchid arrangement is designed to leave a lasting impression, bringing a touch of natures grace into any setting.",
        "meta_title": "test meta title 2",
        "meta_description": "test meta description 2",
        "meta_keywords": null,
        "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_4.jpg",
        "stock_status": "in stock",
        "child": [
          {
            "id": "32251",
            "sku": "KAFL0018-A999-V118",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "38.13",
            "price": "38.13",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose.jpg",
            "bottle_size": "With Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "38.13"
              }
            ],
            "stock_availability": "In Stock"
          },
          {
            "id": "32252",
            "sku": "KAFL0018-A999-V999",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "34.83",
            "price": "34.83",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_3.jpg",
            "bottle_size": "No Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "34.83"
              }
            ],
            "stock_availability": "In Stock"
          }
        ]
      },
      {
        "id": "32253",
        "sku": "KAFL0018",
        "name": "PREMIUM 6 Orange Roses Bouquet ( 6 Fresh Cut Orange Roses )",
        "type": "config",
        "short_description": "This is a short description",
        "description": "Introducing the Kabloom 10 Mixed Purple and White Orchids Bouquet a perfect blend of elegance and sophistication. These stunning orchids, known for their exotic beauty, feature a delightful mix of deep purple and pure white petals, creating a striking contrast that instantly captivates. This bouquet is ideal for a variety of occasions, from celebrating anniversaries and birthdays to expressing heartfelt gratitude or sending best wishes. Whether youre surprising a loved one or brightening up a special event, the Kabloom orchid arrangement is designed to leave a lasting impression, bringing a touch of natures grace into any setting.",
        "meta_title": "test meta title 2",
        "meta_description": "test meta description 2",
        "meta_keywords": null,
        "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_4.jpg",
        "stock_status": "in stock",
        "child": [
          {
            "id": "32251",
            "sku": "KAFL0018-A999-V118",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "38.13",
            "price": "38.13",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose.jpg",
            "bottle_size": "With Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "38.13"
              }
            ],
            "stock_availability": "In Stock"
          },
          {
            "id": "32252",
            "sku": "KAFL0018-A999-V999",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "34.83",
            "price": "34.83",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_3.jpg",
            "bottle_size": "No Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "34.83"
              }
            ],
            "stock_availability": "In Stock"
          }
        ]
      },
      {
        "id": "32253",
        "sku": "KAFL0018",
        "name": "PREMIUM 6 Orange Roses Bouquet ( 6 Fresh Cut Orange Roses )",
        "type": "config",
        "short_description": "This is a short description",
        "description": "Introducing the Kabloom 10 Mixed Purple and White Orchids Bouquet a perfect blend of elegance and sophistication. These stunning orchids, known for their exotic beauty, feature a delightful mix of deep purple and pure white petals, creating a striking contrast that instantly captivates. This bouquet is ideal for a variety of occasions, from celebrating anniversaries and birthdays to expressing heartfelt gratitude or sending best wishes. Whether youre surprising a loved one or brightening up a special event, the Kabloom orchid arrangement is designed to leave a lasting impression, bringing a touch of natures grace into any setting.",
        "meta_title": "test meta title 2",
        "meta_description": "test meta description 2",
        "meta_keywords": null,
        "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_4.jpg",
        "stock_status": "in stock",
        "child": [
          {
            "id": "32251",
            "sku": "KAFL0018-A999-V118",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "38.13",
            "price": "38.13",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose.jpg",
            "bottle_size": "With Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "38.13"
              }
            ],
            "stock_availability": "In Stock"
          },
          {
            "id": "32252",
            "sku": "KAFL0018-A999-V999",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "34.83",
            "price": "34.83",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_3.jpg",
            "bottle_size": "No Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "34.83"
              }
            ],
            "stock_availability": "In Stock"
          }
        ]
      },
      {
        "id": "32253",
        "sku": "KAFL0018",
        "name": "PREMIUM 6 Orange Roses Bouquet ( 6 Fresh Cut Orange Roses )",
        "type": "config",
        "short_description": "This is a short description",
        "description": "Introducing the Kabloom 10 Mixed Purple and White Orchids Bouquet a perfect blend of elegance and sophistication. These stunning orchids, known for their exotic beauty, feature a delightful mix of deep purple and pure white petals, creating a striking contrast that instantly captivates. This bouquet is ideal for a variety of occasions, from celebrating anniversaries and birthdays to expressing heartfelt gratitude or sending best wishes. Whether youre surprising a loved one or brightening up a special event, the Kabloom orchid arrangement is designed to leave a lasting impression, bringing a touch of natures grace into any setting.",
        "meta_title": "test meta title 2",
        "meta_description": "test meta description 2",
        "meta_keywords": null,
        "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_4.jpg",
        "stock_status": "in stock",
        "child": [
          {
            "id": "32251",
            "sku": "KAFL0018-A999-V118",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "38.13",
            "price": "38.13",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose.jpg",
            "bottle_size": "With Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "38.13"
              }
            ],
            "stock_availability": "In Stock"
          },
          {
            "id": "32252",
            "sku": "KAFL0018-A999-V999",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "34.83",
            "price": "34.83",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_3.jpg",
            "bottle_size": "No Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "34.83"
              }
            ],
            "stock_availability": "In Stock"
          }
        ]
      },
      {
        "id": "32253",
        "sku": "KAFL0018",
        "name": "PREMIUM 6 Orange Roses Bouquet ( 6 Fresh Cut Orange Roses )",
        "type": "config",
        "short_description": "This is a short description",
        "description": "Introducing the Kabloom 10 Mixed Purple and White Orchids Bouquet a perfect blend of elegance and sophistication. These stunning orchids, known for their exotic beauty, feature a delightful mix of deep purple and pure white petals, creating a striking contrast that instantly captivates. This bouquet is ideal for a variety of occasions, from celebrating anniversaries and birthdays to expressing heartfelt gratitude or sending best wishes. Whether youre surprising a loved one or brightening up a special event, the Kabloom orchid arrangement is designed to leave a lasting impression, bringing a touch of natures grace into any setting.",
        "meta_title": "test meta title 2",
        "meta_description": "test meta description 2",
        "meta_keywords": null,
        "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_4.jpg",
        "stock_status": "in stock",
        "child": [
          {
            "id": "32251",
            "sku": "KAFL0018-A999-V118",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "38.13",
            "price": "38.13",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose.jpg",
            "bottle_size": "With Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "38.13"
              }
            ],
            "stock_availability": "In Stock"
          },
          {
            "id": "32252",
            "sku": "KAFL0018-A999-V999",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "34.83",
            "price": "34.83",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_3.jpg",
            "bottle_size": "No Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "34.83"
              }
            ],
            "stock_availability": "In Stock"
          }
        ]
      },
      {
        "id": "32253",
        "sku": "KAFL0018",
        "name": "PREMIUM 6 Orange Roses Bouquet ( 6 Fresh Cut Orange Roses )",
        "type": "config",
        "short_description": "This is a short description",
        "description": "Introducing the Kabloom 10 Mixed Purple and White Orchids Bouquet a perfect blend of elegance and sophistication. These stunning orchids, known for their exotic beauty, feature a delightful mix of deep purple and pure white petals, creating a striking contrast that instantly captivates. This bouquet is ideal for a variety of occasions, from celebrating anniversaries and birthdays to expressing heartfelt gratitude or sending best wishes. Whether youre surprising a loved one or brightening up a special event, the Kabloom orchid arrangement is designed to leave a lasting impression, bringing a touch of natures grace into any setting.",
        "meta_title": "test meta title 2",
        "meta_description": "test meta description 2",
        "meta_keywords": null,
        "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_4.jpg",
        "stock_status": "in stock",
        "child": [
          {
            "id": "32251",
            "sku": "KAFL0018-A999-V118",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "38.13",
            "price": "38.13",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose.jpg",
            "bottle_size": "With Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "38.13"
              }
            ],
            "stock_availability": "In Stock"
          },
          {
            "id": "32252",
            "sku": "KAFL0018-A999-V999",
            "short_description": null,
            "description": null,
            "brand_name": null,
            "product_category": false,
            "product_type": false,
            "ingredients": [],
            "meta_title": null,
            "meta_description": null,
            "meta_keywords": null,
            "maximumprice": "34.83",
            "price": "34.83",
            "loc_id": "7375",
            "image": "https://apidev.kabloom.com/media/catalog/product/6/_/6_Orange_Rose_3.jpg",
            "bottle_size": "No Vase",
            "country_of_origin": null,
            "speed_id": [
              {
                "Type": "Farm Direct",
                "Loc_id": "7375",
                "Price": "34.83"
              }
            ],
            "stock_availability": "In Stock"
          }
        ]
      }
]

  module.exports = {
    data: data
  };